import { useEffect, useState } from 'react'
import '../style/foodupdate.scss'
import {getDishByID,getUnitTypes,getCookingTypes} from '../api'
import {useHistory} from 'react-router-dom'
import Navbar from '../component/Navbar';
import HeaderWatch from '../component/HeaderWatch';
export default function FoodWatch(props){
    const{
        id
    } = props.match.params;
    const history = useHistory()
    const[addDish,setDish] = useState();
    const[getUnitTypeName, setUnitTypeName] = useState()
    const[getCookType,setCookType] = useState()
    useEffect(()=>{
        getDishByID(id,setDish);
    },[undefined]);
    useEffect(()=>{
        getUnitTypes(setUnitTypeName);
    },[])
    useEffect(()=>{
        getCookingTypes(setCookType)
    },[])
    const handleGet=(unit_type)=>{
        if(getUnitTypeName){
           for(var t = 0; t<getUnitTypeName.data.length;t++){
               if(getUnitTypeName.data[t].id === unit_type){
                   return getUnitTypeName.data[t].name;
               }
           }
        }
        return null;
    }
    const handleCook=(cook_type)=>{
        if(getCookType){
           for(var b = 0; b<getCookType.data.length;b++){
               if(getCookType.data[b].id === cook_type){
                   return getCookType.data[b].name;
               }
           }
        }
        return null;
    }
   const goTO=(e)=>{
       e.preventDefault();
        history.push(`/dishes/edit/${id}`)
   }
    return(
      <div>
          <Navbar/>
    <div className='food-update'>
        <div className="row align-items-center">
            <div className="col-6">
            <span className='h4'>{addDish ? addDish.data.name : null }</span>
            </div>
            <div className="col-2">
            <input type="submit" value="Изменить" className='btn waves-effect waves-light btn-large blue lighten-1' onClick={(e)=>(goTO(e))}/>
            </div>
        </div>
   
          <div className="row align-items-center">
                   <div className="col-8">
                   <div className="row onerow align-items-center padding">
                        <div className="grid-example col-2">
                            <span className="name">BLS_CODE:</span>
                        </div>
                       <div className="grid-example col-10">
                            <div className="col s12">
                            <span>{addDish ? addDish.data.bls_code : null }</span>
                        </div>
                       </div>
                   </div>
                   <div className="row onerow align-items-center padding">
                        <div className="grid-example col-2">
                            <span className="name">Название:</span>
                        </div>
                       <div className="grid-example col-10">
                            <div className="col s12">
                            {addDish ? addDish.data.name : null }
                        </div>
                       </div>
                   </div>
                   <div className="row onerow align-items-center padding">
                       <div className="grid-example col-2">
                           <span className="name">Описание:</span>
                       </div>
                       <div className="grid-example col-10">
                       <div className="col s12">
                       {addDish ? addDish.data.description: null }
                        </div>
                       </div>
                   </div>
                   <div className="row onerow align-items-center padding">
                       <div className="grid-example col-2">
                           <span className="name">Ссылка на рецепт:</span>
                       </div>
                       <div className="grid-example col-10">
                       <div className="col s12">
                       <a href={addDish ? addDish.data.recipe_link: null }target="_blank">{addDish ? addDish.data.recipe_link: null }</a>
                        </div>
                       </div>
                   </div>
                   <div className="row onerow align-items-center padding">
                       <div className="grid-example col-2">
                           <span className="name">Категория:</span>
                       </div>
                       <div className="grid-example col-10">
                    {
                       addDish? addDish.data.category.map((el,index)=>{
                            return   <span key={index} className='chipCus'>{addDish.data.category[index].name}  </span>
                        }):null
                    }
                       </div>
                   </div> 
                   </div>
                   <div className="col-4">
                    <img src={addDish? addDish.data.image:null} alt='image' className='imageOfTheDish'/>
                   </div>
                </div>
                <div className="row onerow align-items-center justify-content-start padding">
                       <div className="grid-example col-2">
                           <span className="name">Количество порций</span>
                       </div>
                       <div className="grid-example col-3">
                       <span>{addDish? addDish.data.servings:null}</span>
                       </div>
                   </div>
                   <div className="row onerow align-items-center justify-content-start padding">
                       <div className="grid-example col-2">
                           <span className="name">Вес</span>
                       </div>
                       <div className="grid-example col-3">
                       <span>{addDish? addDish.data.weight:null}</span>
                       </div>
                   </div>
                    <div className="ing">
                        <h4 className='h4'>Продукты</h4>
                       <table>
                          <HeaderWatch/>
                          <tbody>
                             {
                              addDish ? addDish.data.ingredients.map((el,index)=>{
                                   return  <tr key={index}>
                                    <td> 
                                      <span>{ addDish.data.ingredients[index].name}</span>
                                    </td>
                                     <td> 
                                      <span>{handleGet(addDish.data.ingredients[index].pivot.unit_type_id)}</span>
                                    </td>
                                     <td>
                                      <span>{ addDish.data.ingredients[index].pivot.unit_type_amount}</span>
                                     </td>
                                     <td>
                                      <span>{ addDish.data.ingredients[index].pivot.density}</span>
                                     </td>
                                     <td>
                                      <span>{ addDish.data.ingredients[index].pivot.gram_per_unit}</span>
                                     </td>
                                     <td>
                                     <span>{handleCook(addDish.data.ingredients[index].pivot.cooking_type_ids)}</span>
                                     </td>
                                     <td> 
                                      <span>{addDish.data.ingredients[index].pivot.waste_percentage}</span>
                                    </td>
                                     <td> 
                                      <span>{ addDish.data.ingredients[index].pivot.heat_ratio}</span>
                                    </td>
                                     <td>
                                      <span>{ addDish.data.ingredients[index].pivot.cooking_time}</span>
                                     </td>
                                     <td>
                                      <span>{ addDish.data.ingredients[index].pivot.gross_weight}</span>
                                     </td>
                                     <td>
                                      <span>{ addDish.data.ingredients[index].pivot.weight}</span>
                                     </td>
                                    </tr>
                               }):null
                           }
                            
                          </tbody>
                       </table>
                    </div>
                    <h4 className='recipe'>Рецепт:</h4>
                   <div className="group-steps">
                 {
                     addDish && addDish.data.recipe_description!==null ? addDish.data.recipe_description.map((item,index)=>(
                        
                        <div className="row onerow align-items-center justify-content-between" key={index}>
                        <div className="grid-example col-2">
                            <span className="name">Рецепт:(шаг {index+1})</span>
                        </div>
                        <div className="grid-example col-9 padding">
                       <span>
                         {
                            addDish.data.recipe_description[index].text
                         }
                       </span>
                        </div>
                        </div> 
                       
                     )) :null
                 }
                  </div>
                </div>
                </div>
    )
}