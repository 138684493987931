import {Doughnut} from 'react-chartjs-2'
import '../style/chart.scss'
export default function Charts({stat,week}){

  const handleP=()=>{
    if(stat.total_proteins==null || stat.total_calories == null) {return 0;}
    return (stat.total_proteins*4)/stat.total_calories;
  }
  const handleF=()=>{
    if(stat.total_fats==null || stat.total_calories == null) {return 0;}
    return (stat.total_fats*9)/stat.total_calories;
  }
  const handleC=()=>{
    if(stat.total_carbohydrates==null || stat.total_calories == null) {return 0;}
    return ( (stat.total_fiber * 2) + (stat.total_carbohydrates * 4 )) / stat.total_calories;
  }
    const data = {
        labels: [
            'Total proteins',
            'Total fats',
            'Total fibers'
          ],
        datasets: [
          {
            label: '# of Votes',
            data: [
                Math.round(handleP()*100),
                Math.round(handleF()*100),
                Math.round(handleC()*100)
              ],
            backgroundColor: [
              'rgba(255, 99, 132, 0.5)',
              'rgba(54, 162, 235, 0.5)',
              'rgba(255, 206, 86, 0.5)'
            ],
            borderColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)'
            ],
            borderWidth: 2,
          },
        ],
      };
     
  
    return(
        <div className='charts'>     
            <div className='header'>
            </div>
            <Doughnut data={data}/>
            <div className="info__">
                  <span><strong>Total weight:</strong> {stat.total_weight}</span>
                  <span><strong>Total calories:</strong> {stat.total_calories}</span>
                  <span><strong>Total proteins:</strong> {Math.round(handleP()*100)}%</span>
                  <span><strong>Total fats: </strong>{Math.round(handleF()*100)}%</span>
                  <span><strong>Total carbohydrates:</strong> {Math.round(handleC()*100)}%</span>
            </div>
            {
              week==true?
              <div className="info__">
              <span><strong>Average calories:</strong> {Number(stat.total_calories)/7}</span>
              <span><strong>Average proteins:</strong> {stat.total_proteins/7}</span>
              <span><strong>Average fats: </strong>{stat.total_fats/7}</span>
              <span><strong>Average carbohydrates:</strong> {stat.total_carbohydrates/7}</span>
              </div>
              :null
            }
            <div className="calc">
               <div className="item-one">
                <span className='num'>
                  <span className="ans">{stat.total_proteins}</span>
                (proteins) * <strong>4</strong> / <span className="ans">{stat.total_calories}</span>(total calories) = 
                </span>
                <span className='ans'>{handleP()}</span>
               </div>
               <div className="item-one">
              <span className='num'><span className="ans">{stat.total_fats}</span>(fats) * <strong>9</strong>/ <span className="ans">{stat.total_calories} </span>
              (total calories) = </span><span className='ans'>{handleF()}</span>
               </div>
               <div className="item-one" style={{marginBottom:'50px'}}>
          <span className='num'>(( <span className='ans'>{stat.total_fiber}</span> (fibers) * 2) + (<span className="ans">{stat.total_carbohydrates}</span>(carbohydrates) * <strong>4</strong> ))/ <span className="ans">{stat.total_calories} </span>(total calories) = </span><span className='ans'>{handleC()}</span>
               </div>
            </div>
        </div>
    )
}