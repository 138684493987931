import SearchPage from "./layer/SearchPage";
import {BrowserRouter as Router, Route , Switch} from 'react-router-dom'
import FoodWatch from "./layer/FoodWatch";
import FoodUpdate from "./layer/FoodUpdate";
import Login from "./layer/Login";
import Register from "./layer/Register";
import Statistics from "./layer/Statistics";
import Main from "./layer/Main";
import RecipePage from "./layer/RecipePage";
import DishesList from "./layer/DishesList";
import {CompareDishes} from "./layer/CompareDishes";
function App() {
  return (
   <Router>
     <Switch>
       <Route path='/dishes/:id' exact component={SearchPage}/>
       <Route path='/dishes/view/:id' exact component={FoodWatch}/>
       <Route path='/dishes/edit/:id' exact component={FoodUpdate}/>
       <Route path='/dishes/view/dishes-by-micros/:id' component={CompareDishes}/>
       <Route path='/' exact component={Login}/>
       <Route path='/register' component={Register}/>
       <Route path='/statistics' component={Statistics}/>
       <Route path='/main' component={Main}/>
       <Route path='/recipes' component={RecipePage}/>
       <Route path='/dishes-list' component={DishesList}/>
     </Switch>
   </Router>
  );
}

export default App;
