import React, { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import Slider from 'rc-slider';

import { getNutritionForUser, saveNutritionForUser } from '../api'
import { getMissingNumberInArray, getPartsDivision } from '../helpers';

import 'rc-slider/assets/index.css';
import '../style/nutrition-calculate.scss';


const NutritionSlider = ({ dataForCalculation, onUserDataLoad, generateMenu }) => {
  const USER_ID = 19 // TODO: remove hardcoded id

  const [sum, setSum] = useState(0)
  const [initialHandle, setInitialHandle] = useState(true)
  const [previousSliderIndexes, setPreviousSliderIndexes] = useState([])
  const [savedNutritions, setSavedNutritions] = useState({})
  const [nutritions, setNutritions] = useState({
    proteins: 0,
    fats: 0,
    carbohydrates: 0,
  })

  const fetchUserNutrition = async () => {
    await getNutritionForUser(
      {
        user_id: USER_ID,
        setInfo: (response) => {
          const { data } = response
          const { protein_percent, fat_percent, carbohydrates_percent } = data

          setNutritions({
            proteins: protein_percent,
            fats: fat_percent,
            carbohydrates: carbohydrates_percent,
          })
          setSavedNutritions({
            proteins: protein_percent,
            fats: fat_percent,
            carbohydrates: carbohydrates_percent,
          })
          setInitialHandle(false)
          onUserDataLoad(data)
        }
      })
  }

  const handleNutritionChange = (value, type) => {
    const currentSliderIndex = [...Object.keys(nutritions)].indexOf(type);
    let prevSlidersUsed;
    let userSettedNutritions = {
      ...nutritions,
      [type]: value,
    }

    if (initialHandle || previousSliderIndexes.length === 0) {
      let initialLack = getPartsDivision(100 - value, 2)
      Object.keys(userSettedNutritions).forEach(v => {
        if (userSettedNutritions[v] !== value) {
          userSettedNutritions[v] = initialLack[0] ?? initialLack
          if (initialLack && initialLack.length) initialLack = initialLack.pop()
        }
      })
      setInitialHandle(false)
    }

    // keep autofill slider indexes if used same range slider
    if (previousSliderIndexes.length > 1 && previousSliderIndexes.includes(currentSliderIndex)) {
      prevSlidersUsed = [...previousSliderIndexes]
    } else {
      prevSlidersUsed = [
        ...previousSliderIndexes,
        currentSliderIndex,
      ]
    }

    // always 2 of 3 actual sliders in use
    if (prevSlidersUsed.length > 2) {
      prevSlidersUsed.shift()
    }

    if (prevSlidersUsed.length === 2) {
      const sliderToUpdate = getMissingNumberInArray(prevSlidersUsed, prevSlidersUsed.length);

      // calculate third range to fit 100% sum
      let lack = 100 - [...Object.values(userSettedNutritions)].reduce((sum, value, i) => {
        return sliderToUpdate !== i ? (sum + value) : sum
      }, 0)

      if (lack < 0) lack = 0 // prevent negative digits

      const autoUpdateNutritions = (nut) => {
        Object.keys(nut).forEach((key, idx) => {
          if (idx === sliderToUpdate) {
            nut[key] = lack
          }
        });
        return nut;
      }

      const autoFilledNuts = autoUpdateNutritions(userSettedNutritions)

      const sum = Object.values(autoFilledNuts).reduce((a, b) => a + b)
      if (sum > 100 && Object.values(autoFilledNuts).includes(0)) {
        let initialLack = 100 - value
        Object.entries(autoFilledNuts).forEach(entry => {
          if ((autoFilledNuts[entry[0]] !== value || entry[0] !== type) && autoFilledNuts[entry[0]] !== 0) {
            autoFilledNuts[entry[0]] = initialLack
          }
        })
      }

      setNutritions({
        ...autoFilledNuts,
        [type]: value,
      })
    }

    setPreviousSliderIndexes(prevSlidersUsed)
    setNutritions(userSettedNutritions)
  }

  const submitHandle = async (e) => {
    e.preventDefault()

    const { proteins, fats, carbohydrates } = nutritions
    const { purpose_id } = dataForCalculation

    if (!purpose_id) {
      toast.error('Пожалуйста, выберите цель')
      return
    }


    await saveNutritionForUser({
      user_id: USER_ID,
      protein_percent: proteins,
      fat_percent: fats,
      carbohydrates_percent: carbohydrates,
      calorie_type: purpose_id,
    })

    setSavedNutritions({
      proteins,
      fats,
      carbohydrates,
    })
  }

  const resetValues = () => {
    setNutritions(savedNutritions)
  }

  const saveAndGenerateMenu = async () => {
    const { proteins, fats, carbohydrates } = nutritions
    const { purpose_id, day } = dataForCalculation

    if (!purpose_id) {
      toast.error('Пожалуйста, выберите цель')
      return
    }

    if (!day) {
      toast.error('Пожалуйста, выберите день')
      return
    }

    await saveNutritionForUser({
      user_id: USER_ID,
      protein_percent: proteins,
      fat_percent: fats,
      carbohydrates_percent: carbohydrates,
      calorie_type: purpose_id,
    })

    await generateMenu(nutritions)
  }

  useEffect(() => {
    fetchUserNutrition()
  }, [])

  useEffect(() => {
    setSum(Object.values(nutritions).reduce((a, b) => a + b))
  }, [nutritions])



  return (
    <div className="nutrition-calculate">
      <div className="nutrition-calculate__row">
        <div className="nutrition-calculate__label">Белки: {nutritions.proteins}</div>
        <Slider
          onChange={(e) => handleNutritionChange(e, 'proteins')}
          value={nutritions.proteins}
          min={0} max={100} step={5}
        />
      </div>
      <div className="nutrition-calculate__row">
        <div className="nutrition-calculate__label">Жиры: {nutritions.fats}</div>
        <Slider
          onChange={(e) => handleNutritionChange(e, 'fats')}
          value={nutritions.fats}
          min={0} max={100} step={5}
        />
      </div>
      <div className="nutrition-calculate__row">
        <div className="nutrition-calculate__label">Углеводы: {nutritions.carbohydrates}</div>
        <Slider
          onChange={(e) => handleNutritionChange(e, 'carbohydrates')}
          value={nutritions.carbohydrates}
          min={0} max={100} step={5}
        />
      </div>

      <p>{sum}%</p>

      <button
        className="nutrition-calculate__submit btn btn-small blue"
        disabled={sum > 100 || sum < 100}
        onClick={submitHandle}
      >
        Сохранить
      </button>
      <button
        className="nutrition-calculate__submit btn btn-small blue lighten-2"
        disabled={sum > 100 || sum < 100}
        onClick={saveAndGenerateMenu}
      >
        Сгенерировать меню
      </button>
      <button
        className="nutrition-calculate__submit btn btn-small blue lighten-3"
        onClick={resetValues}
      >
        Сбросить значения
      </button>

      {sum > 100
        ? <div className="nutrition-calculate__error">Сумма не должна превышать 100%</div>
        : null
      }
      {sum < 100
        ? <div className="nutrition-calculate__error">Сумма не должна быть меньше 100%</div>
        : null
      }
    </div>
  );
};

export default NutritionSlider;
