import MenuItem from "../component/MenuItem";
import '../style/menu.scss'
import { Swiper, SwiperSlide} from "swiper/react";
import SwiperCore, {Pagination} from 'swiper';
import 'swiper/components/pagination/pagination.min.css';
import 'swiper/swiper.scss';
import Preloader from "../component/Preloader";
SwiperCore.use([Pagination]);
export default function MenuDaily({info,arr,setCall=Function.prototype,call,setPressed=Function.prototype}){

    return(
<div>
    {
    info && arr ?
         <div className='menu'>
             <div className={info?"changeMeal":"none"}>
             <button className="generate-new-menu mt-4" onClick={()=>(setCall(!call),setPressed(true))}>Сгенерировать новое меню на день</button>
             </div>
        {
           arr ? arr.map((item,index)=>{
                return  <div className='swiper__content' key={index}>
                <div className="heading__name">
                <span>{item.list && item.list.length>0 ? item.name:null}</span>
                </div>
                <Swiper
                    pagination={{ clickable: true}}
                >
                    {
                       item.list ? item.list.map((el,index)=>{
                            return <SwiperSlide key={index}>
                                <div className='menu__content'>
                                    <MenuItem
                                      productName={el.name}
                                      productImage={el.photo!=null? el.photo : null}
                                      lights={[]}
                                      weight={el.weight}
                                      kkals={el.kilocalories}
                                      link={'el.recipe_description'}
                                   />
                                </div>
                            </SwiperSlide>
                        }):null
                    }
                </Swiper>
            </div>
            }):<Preloader/>
        }
         </div>
         :<Preloader/>
        }
    </div>
    )
}
