import '../style/recipes.scss'
export default function ModalLights({lights,closePlanModal=Function.prototype}){
    return(
    <div className="menu__item__modal__content" style={{height:'auto',position:'absolute',width:'300px',marginTop:'10px',marginLeft:'10px'}}>
        <div className="menu__item__modal__content__inside">
            <span className='menu__item__modal__content__inside__btn' onClick={()=>(closePlanModal(false))} >&#9747;</span> 
              <div className="menu__item__modal__inside__text" onClick={(e)=>{e.stopPropagation()}}>
              <table  className='text__table'>
                  <tbody>
                    {
                     lights?   lights.map((it,q)=>(
                                   <tr key={q}>
                                        <td>
                                            <div className='balls__table' style={{background:`${it.colorHex}`}}></div>
                                        </td>
                                        <td>{it.name}</td>
                                        <td className='second'>{it.weight+ " "+it.unit}</td>
                                   </tr>
                            
                        )):null
                    }
                    </tbody>
                </table>
              </div>
        </div>
    </div>
    )
}