import {actionType} from './constants'

const defaultState = {
    initial_ing : [],
    index: 0,
    opened:true,
    add_step:[],
    recipe:[]
}

export function reducer(state = defaultState, action){
    switch(action.type){
        case actionType.SET_ING:
            return {...state, initial_ing: action.payload}
        case actionType.SET_INDEX:
            return {...state, index: action.payload}
        case actionType.SET_OPEN:
            return {...state, opened:action.payload}
        case actionType.SET_STEP:
            return {...state, add_step:action.payload}
        case actionType.SET_RECIPE:
            return {...state, recipe:action.payload}
        default:
            return state
    }
}