import '../style/filter.scss'
import Preloader from '../component/Preloader'
export default function WithIngs({ingsList,getInfo1=Function.prototype,putInfo1}){
    const handleAddToingsList = (name) =>{
        if(putInfo1.includes(name)){
            getInfo1(putInfo1.filter(x=>x!=name))
            return;
        }
        else{
            getInfo1(putInfo1=>[...putInfo1,name])
        }
    }
    return(
        <div className="with_categories">
              <div className="search__item">
              {
                   ingsList? ingsList.map((p,pp)=>(
                        <div className={putInfo1.includes(p.id)?"search__item__check box-shadow":"search__item__one"} key={p.id} onClick={()=>(handleAddToingsList(p.id))}>
                            <span>{p.name}</span>
                        </div>
                   )):<Preloader/>
               }
              </div>
        </div>
    )
}