import {Link} from 'react-router-dom'

export default function ItemTable(props){
    const{
        id,name,protein,fat,carbohydrate,categories
    } = props
    return(
       <div className='row one-item'>
                            <div className='col-1'>{id}</div>
                            <div className='col-2'>{name}</div> 
                            <div className='col-2'>{protein}</div>
                            <div className='col-2'>{fat}</div>
                            <div className='col-1'>{carbohydrate}</div>
                            <div className='col-2'>
                                <span className='category yellow lighten-4'>
                                {
                                  categories!= null? categories.map((el,index)=>{
                                    return   <span key={index}>{categories[index].name}</span>
                                    }):null
                                }
                             </span>
                            </div>
                            <div className='col-2'>
                            <Link to={`./edit/${id}`}>
                                <button className="btn btn-small light-green lighten-1">Изменить</button>
                            </Link>
                            <br/>
                            <Link to={`./view/${id}`}>
                                <button className="btn btn-small pink lighten-2">Просмотр</button>
                            </Link>
                            <Link to={`/dishes/view/dishes-by-micros/${id}`}>
                                <button className="btn btn-small blue lighten-2">Проверить</button>
                            </Link>
                            </div>
       </div>
    )
}