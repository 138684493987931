import SearchPlan from "../component/SearchPlan"
import '../style/recipes.scss'
import '../style/style.scss'
import CardDishes from '../component/CardDishes'
import {useEffect, useState} from 'react'
import Pagination from "../component/Pagination"
import {getIng} from "../api"
import Preloader from '../component/Preloader'
import MainPage from "./MainPage"
export default function DishesList(){
    const[currentPage,setCurrentPage] = useState(1)
    const [chooseName,setChooseName] = useState([]);
    const[textSearch,setTextSearch] = useState('')
    const[total,setTotal] = useState(0)
    useEffect(()=>{
        getIng(currentPage,textSearch).then((res) => {
            setChooseName(res.data.data)
        })
    },[currentPage,textSearch])
    const onPageChanged = (currentPage)=>{
        if(currentPage.currentPage == undefined){
            return;
        }
        setCurrentPage(currentPage.currentPage)
    }   
    console.log(chooseName)
    return(
        <div className="recipes__page">
            <MainPage/>
            <SearchPlan cb={setTextSearch}/>
            <div className="card__formula">
            <p>b = baseline_energy_points + baseline_sat_fat_points + baseline_total_sugar_point + baseline_sodium_point</p>
            <p>m = modifying_point_fvnl + modifying_point_fibre + modifying_point_protein</p>
            <p>range = fopl_calibration_category_range</p>
            <p>more = fopl_calibration_category_more</p>
            </div>
            <div className="recipes__page__itemsList">
            {
                chooseName ?
                chooseName.map((mp,index)=>(
                    <CardDishes
                    id={mp.id}
                    key={index}
                    productName={mp.name}
                    lights={mp.lights}
                    healthFactor={mp.health_factor}
                    listProducts={mp.magnum_products}
                    micros={mp.micronutrients}
                />
                )):null
            }
            </div>
            {
               chooseName?
                    <Pagination
                        totalRecords={1274}
                        pageLimit={12}
                        pageNeighbours={1}
                        onPageChanged={onPageChanged}
                        currentPage={currentPage}
                    />
                :null
            }
        </div>
    )
}