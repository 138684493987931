import { useState } from 'react'
import '../style/menu.scss'

export default function DropDown({cb, onUpdate}){
    const [type,settype] = useState({
        purpose:false,
        day:false
    })
    const[select,setSelect] = useState({
        purpose_id:'',
        day:''
    })
    const purpose_ids=[
        'Выберите цель',
        'Сбросить вес',
        'Быть в тонусе',
        'Набор массы'
    ]
    const days = [
        'Выберите день',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7'
    ]

    const changeField = (e)=>{
        e.preventDefault();
        setSelect({...select,[e.target.name]:e.target.value})
        onUpdate({...select,[e.target.name]:e.target.value})
    }
      const handleSubmit=(e)=>{
          e.preventDefault()
          if(select.day==''){
              settype({...type,day:true})
          }
          if(select.purpose_id==''){
              settype({...type,purpose:true})
          }
        if(select.day!='' && select.purpose_id!=''){
            cb(select)
        }
      }
    return(
        <div className="drop__down">
            <form action="" className="drop__down__form" onSubmit={(e)=>(handleSubmit(e))}>
            <label htmlFor="purpose_id">Purpose Id</label>
                <select name="purpose_id" id="purpose_id" className='browser-default' onChange={(e)=>{changeField(e)}}>

                    {
                        purpose_ids.map((el,index)=>{
                          return <option
                            value={index==0?'':index}
                            key={el}
                            disabled={index==0? true:false}
                            selected={index==0? true:false}
                            >{el}</option>
                        })
                    }
                </select>
            <p className={type.purpose?"red":'none'}>Please, select the purpose id</p>
                <label htmlFor="days">Days</label>
               <select name="day" id="day" className='browser-default' onChange={(e)=>{changeField(e)}}>
                {
                        days.map((el,index)=>{
                        return <option
                            value={index==0?'':index}
                            disabled={index==0? true:false}
                            selected={index==0? true:false}
                            key={el}
                            >{el}</option>
                        })
                    }
                </select>
                <p className={type.day?"red":'none'}>Please, select the day</p>
                <input type="submit" className='btn btn-primary' value='Select' disabled={select.day=='' || select.purpose_id=='' ?true:false}/>
            </form>
        </div>
    )
}
