import '../style/filter.scss'
import {useState,useEffect} from 'react'
import { getDishCategories, getMicro} from '../api'
import WithIngs from './WithIngs'
import WithCategories from './WithCategories'
import TwoButtons from '../component/TwoButtons'
export default function Filter({closeModal}){
    const[getCat,setCat] = useState()
    const[ingsList,setIngsList] = useState()
    const[getCategories,setGetCategories] = useState([])
    const[getIngsList,setGetIngsList] = useState([])
    const[clickB, setClickB] = useState(true)
    useEffect(() => {
       getDishCategories(setCat)
       getMicro(setIngsList)
    }, [])
    const handleSubmitFilter=()=>{ 
        closeModal(false)
    }
    const deleteFilter=()=>{
        setGetCategories([]);
        setGetIngsList([]);
    }
    return(
        <div className="filter">
           <div className="filter__body">
           <div className="filter__navbar">
                <div className="svg" onClick={()=>(closeModal(false))}>
                    <svg width="23" height="16" viewBox="0 0 23 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.292893 7.29289C-0.0976311 7.68342 -0.0976311 8.31658 0.292893 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292893 7.29289ZM23 7L1 7V9L23 9V7Z" fill="#9F7DFF"/>
                    </svg>
                </div>
                <span>Фильтры</span>
                <button className='filter__navbar__remove' onClick={()=>(deleteFilter())}>Сбросить</button>
            </div>
            <div className="filter__content">
                <TwoButtons
                   clickB={clickB}
                   setClickB={setClickB}
                   firstName='По категориям'
                   lastName='По полезности'
                />
            </div>
                <div>
                    {
                        clickB?
                            <WithCategories 
                                list={getCat?getCat:null} 
                                getInfo={setGetCategories}
                                putInfo={getCategories}
                                />:
                                <WithIngs 
                                    ingsList={ingsList?ingsList:null} 
                                    getInfo1={setGetIngsList}
                                    putInfo1={getIngsList}
                                />
                    }
                </div>
            <div className="filter__submit">
                <button onClick={()=>(handleSubmitFilter())}>Применить</button>
            </div>
           </div>
        </div>
    )
}