import '../style/search.scss'
import {useHistory} from 'react-router-dom'
import {useState} from 'react'
import ModalLights from './ModalLights';
import CardTable from './CardTable';
import CardFormula from './CardFormula';
import ListProducts from './ListProducts';
import MicroModal from './MicroModal';
export default function CardDishes(props){
    const [openM,setopenM] =useState(false)
    const[range,setRange] = useState(0)
    const[openMicro,setOpenMicro] = useState(false)
    const history = useHistory();
    const {
            id,
            productName,
            lights,
            listProducts=[],
            healthFactor,
            micros
        } = props;
        const handlePush=(e)=>{
            history.push(`/recipe/${id}`)
        }
        const defineColor=(num)=>{
            let r = Number(num)*2
                if(r>0 && r < 5){
                    return '#fc1e2d'
                }
                if(r>7 && r<11){
                    return '#51E42C'
                }
                if(r>4 && r<8){
                    return '#ffe70d'
                }
            }
            console.log(range)
    return(
        <div className='cardSearch'>
        <div className="cardSearch__item">
            <div className="cardSearch__item__desc">
                    <div className="cardSearch__item__desc__navbar">
                        <div className="balls" onClick={()=>(setopenM(true))}>
                            {
                             lights?    lights.map((el,index)=>(
                                    <div 
                                        className="balls__b3" 
                                        key={index} 
                                        style={{background:`${el.colorHex}`}}
                                        ></div>
                                )):null
                            }
                        </div>
                    </div>
                <div className="group_">
                   <div className="group__info">
                   <div className="cardSearch__item__desc__heading">
                        <span>{productName}</span>
                    </div>
                   </div>
                    <div className="group__number">
                    <div className="progress-ul">
                    <svg viewBox="-2 -2 40 40" className="circular-chart">
                <path className="circleBack"
                    strokeDasharray="100, 100"
                    d="M18 2.0845
                    a 15.9155 15.9155 0 0 1 0 31.831
                    a 15.9155 15.9155 0 0 1 0 -31.831"
                />
                <path className="circle"
                    strokeDasharray={`${(Number(range))*100/5},100`}
                    stroke={defineColor(Number(range))}
                    d="M18 2.0845
                    a 15.9155 15.9155 0 0 1 0 31.831
                    a 15.9155 15.9155 0 0 1 0 -31.831"
                />
                </svg>
                <div className="progress-ul__info">
                        <p className="progress-ul__number">{healthFactor ? Math.round( Number(healthFactor.hsr_star_points))/2:0}</p>
                  <div className="progress-ul__abs"></div>
                </div>
              </div>
            </div>
            <button className='btn btn-success microButton' onClick={()=>(setOpenMicro(true))}>Micro</button>
            </div>   
        </div>
        <ListProducts listProducts={listProducts}/>
        {
            openMicro? <MicroModal micros={micros} closeModal={setOpenMicro}/>:null
        }
        </div>
            {
                openM? <ModalLights lights={lights} closePlanModal={setopenM}/> :null
            }
             <div>
              {
                healthFactor ?
                <CardFormula formula={healthFactor} setRange={setRange}/>
                :null
            }
             <CardTable healthFactor={healthFactor}/>
             </div>
        </div>
    )
}