function InputText(props){
    const {
        cb,
        defaultValue,
        name
    } = props
    return(
        <input 
            type="text" 
            name={name}
            required
            defaultValue={defaultValue}
            onChange={(e)=>{
                    cb(e)
            }}
        />
    )
}
export default InputText;