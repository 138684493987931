import '../style/recipes.scss'
export default function CardTable({healthFactor}){
    console.log(healthFactor)
    return(
    <div className="card__table__content">
        <div className="card__table__content__inside">
              <div className="card__table__inside__text" onClick={(e)=>{e.stopPropagation()}}>
              <table  className='text__table'>
                  <tbody>
                    {
                     healthFactor?  Object.keys(healthFactor).map((it,q)=>(
                                   <tr key={q}>
                                        <td>{it}</td>
                                        <td>{healthFactor[it]}</td>
                                   </tr>
                            
                        )):null
                    }
                    </tbody>
                </table>
              </div>
        </div>
    </div>
    )
}