import '../style/menu.scss'
import { toast, Toaster } from 'react-hot-toast';
import MenuDaily from './MenuDaily'
import Charts from './Charts'
import {
    getDayPlan,
    getRecommended,
    getByPurposeIdDay,
    generateNewMenu
} from '../api';
import {useState,useEffect} from 'react'
import Weekly from './Weekly'
import MainPage from './MainPage';
import DropDown from '../component/DropDown';
import TableItem from '../component/TableItem';
import Preloader from '../component/Preloader';
import Preferences from '../component/Preferences';
import NutritionSlider from '../component/NutritionSlider';
import NewTableItem from '../component/NewTableItem';

export default function Statistics(){
    const custom = {
        total_calories: 1,
        total_carbohydrates:0,
        total_fats: 0,
        total_proteins: 0,
        total_weight: 0,
        total_fiber:0
        }
    const [change, setChange] = useState(true)
    const[info,setInfo] = useState();
    const[rec,setRec] = useState();
    const[call,setCall] = useState(false)
    const[pressed,setPressed] = useState(false)
    const [newTableItemShow, setNewTableItemShow] = useState(false)
    const [newTableInfo, setNewTableInfo] = useState(null)
    const[weekly,setweekly] = useState();
    const[selectInfoTable,setSelectInfoTable] = useState()
    const [selectInfo,setSelectInfo] = useState({
        purpose_id:'',
        day:''
    });
    const [dataForCalculation, setDataForCalculation] = useState({
        purpose_id:'',
        day:''
    });
    const[choosePref,setChoosePref]=useState([])
    const[arr,setArr] = useState([
        {
            name:'Завтрак',
            list:[]
        },
        {
            name:'',
            list:[]
        },
        {
            name:'Обед',
            list:[]
        },
        {
            name:'',
            list:[]
        },
        {
            name:'Ужин',
            list:[]
        },
        {
            name:'',
            list:[]
        }
    ])
    useEffect(()=>{
        getRecommended(setRec);
        setNewTableItemShow(false)
    },[])
    useEffect(()=>{
        setInfo();
        if(selectInfo.day=='' && selectInfo.purpose_id=='')return;
        if(pressed==true){
            getDayPlan(setInfo,selectInfo.purpose_id,selectInfo.day,choosePref);
            setNewTableItemShow(false)
        }
    },[call])
    useEffect(()=>{
        if(selectInfo.purpose_id !== '' && selectInfo.day !== '') {
                getByPurposeIdDay(setSelectInfoTable,selectInfo.purpose_id,selectInfo.day);
                getDayPlan(setInfo,selectInfo.purpose_id,selectInfo.day,choosePref);
                setNewTableItemShow(false)
        }
    },[selectInfo]);

    const generateMenu = async (e) => {
        if (!dataForCalculation.purpose_id) {
            toast.error('Пожалуйста, выберите цель')
            return false
        }
        if (!dataForCalculation.day) {
            toast.error('Пожалуйста, выберите день')
            return false
        }

        const response = await generateNewMenu()

        if (response && response.data) {
            const menus = response.data
            setNewTableItemShow(true)
            setNewTableInfo([
                {
                    id: 'breakfast',
                    ...menus['breakfast']
                },
                {
                    id: 'snack_one',
                    ...menus['snack_one']
                },
                {
                    id: 'lunch',
                    ...menus['lunch']
                },
                {
                    id: 'snack_two',
                    ...menus['snack_two']
                },
                {
                    id: 'dinner',
                    ...menus['dinner']
                },
                {
                    id: 'snack_three',
                    ...menus['snack_three']
                }
            ])
            toast.success('Меню сгенерировано')
        }
    }

    const updateInfoForCalculation = (e) => {
        setDataForCalculation(e)
    }

    // TODO: enable this after select refactored
    const onUserDataLoad = (data) => {
        const { calorie_type } = data
        // setDataForCalculation({ ...dataForCalculation, purpose_id: calorie_type })
    }

    if(info){
        arr[0]={
            name:'Завтрак',
            list: info.breakfast_dishes
        }
        arr[1]={
            name:'Перекус 1',
            list:info.snack_one_dishes
        }
        arr[2]={
            name:'Обед',
            list:info.lunch_dishes
        }
        arr[3]={
            name:'Перекус 2',
            list:info.snack_two_dishes
        }
        arr[4]={
            name:'Ужин',
            list:info.dinner_dishes
        }
        arr[5]={
            name:'Перекус 3',
            list:info.snack_three_dishes
        }
    }
    return(
        <div>
            <MainPage/>
            <Toaster position="top-right" />
        <div className='stat-page'>
            <div className="btn__choose">
                <button className='day'>Daily Plan</button>
                {/* <button className={change==false?'day':'week'} onClick={()=>{setChange(false)}}>Weekly Plan</button> */}
            </div>
           <div className="stat-page-drop-down">
           <DropDown cb={setSelectInfo} onUpdate={updateInfoForCalculation}/>
           <Preferences setChoosePref={setChoosePref}/>
               <NutritionSlider dataForCalculation={dataForCalculation} onUserDataLoad={onUserDataLoad} generateMenu={generateMenu} />
           </div>
            {
                selectInfo.day!=''&&selectInfo.purpose_id!='' && !newTableItemShow
                ?
            <div className='statistics'>
                    <div className="statistics__menu">
                        {
                            change == true?
                            <div>
                                <h4 style={{marginTop:'30px'}}>Recommended calories: {rec}</h4>
                               {
                                   selectInfo.day!='' && selectInfo.purpose_id!='' ?
                                  <div>
                                       <TableItem talbeObject={selectInfoTable?selectInfoTable:[]}/>
                                       {
                                            change==true ?
                                            <div>
                                            {
                                                info?
                                                <Charts stat={info && info.statistics? info.statistics : custom} week={false}/>
                                                :<Preloader/>
                                            }
                                            </div> : <Charts stat={weekly && weekly.statistics?weekly.statistics:custom} week={true}/>
                                        }
                                  </div>
                                   :
                                   null
                               }
                            </div>
                            : <Weekly weekly={weekly} recom={rec}/>
                        }
                    </div>
                    <div className="statistics__charts">
                    {
                        pressed ?
                        <div className="">
                            {
                        info || arr.length?
                            <MenuDaily info={info? info:null} call={call} arr={arr} setCall={setCall} setPressed={setPressed}/>
                            :<Preloader/>
                            }
                        </div>
                        :   <MenuDaily info={info? info:null} call={call} arr={arr} setCall={setCall} setPressed={setPressed}/>
                    }
                    </div>
            </div>
                  : (newTableItemShow ? <NewTableItem menus={newTableInfo} /> : null)
            }
        </div>
        </div>
    )
}
