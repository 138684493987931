import {useEffect,useState} from 'react'
import { getComparedDishes } from '../api'
import Navbar from '../component/Navbar'
import Preloader from '../component/Preloader'
import '../style/filter.scss'

export const CompareDishes=(props)=>{
    const{
        id
    } = props.match.params
    const [infoDishes,setInfoDishes] = useState()
    useEffect(()=>{
        getComparedDishes(id,setInfoDishes)
    },[id])
const arr = {
    kilocalories: 'Килокалории',
    carbohydrate: 'Углеводы',
    fat: 'Жиры',
    protein: 'Белки',
    saturated_fat:'Насыщенные жиры',
    sodium_chloride:'Хлорид натрия',
    kilocalories_with_fiber:'Килокалории с волокнами',
    fiber:'Пищевые волокна'
}
    return(
        <div>
            <Navbar/>
            {
                infoDishes?
                <div className="container">
                <h6 className='dish-name'>{infoDishes.dish[0].name}</h6>
                <div className='list-micros'>
                    <div className="compare-item">
                        <p className='headings-dishes'>Имя</p>
                    {
                        Object.keys(infoDishes.old).map((el,index)=>(
                                 <p key={el}>{el=='sodium_chloride'?null:arr[el]}</p>
                        ))
                    }
                    </div>
                    <div className="compare-item">
                    <p className='headings-dishes'>немецкая база</p>
                    {
                        Object.keys(infoDishes.old).map((el,index)=>(
                                 <p key={el}>{el=='sodium_chloride'?null:infoDishes.old[el]}</p>
                        ))
                    }
                    </div>
                    <div className="compare-item">
                    <p className='headings-dishes'>конструктор блюд</p>
                    {
                        Object.keys(infoDishes.new).map((el,index)=>(
                                 <p key={el}>{el=='sodium_chloride'?null:infoDishes.new[el]}</p>
                        ))
                    }
                    </div>
                </div>
                </div>
                :<Preloader/>
            }
        </div>
    )
}
