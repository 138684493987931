import '../style/table.scss'

export default function NewTableItem ({ menus }) {
  const names = [
    'Завтрак',
    'Перекус 1',
    'Обед',
    'Перекус 2',
    'Ужин',
    'Перекус 3'
  ]

  return (
    <div className="table__item new-table">
      {menus && menus.map((menu, i) => (
        <div className="element" key={menu.id}>
          <div className="element__item__el">
            <div className="heading">
              <span><strong>{names[i]}</strong></span>
            </div>
            <div className="table__heading">
              <span><i>ID</i></span>
              <span><i>Name</i></span>
            </div>
            {menu.dishes.length ?
              menu.dishes.map((dish) => (
                <div className="element__item__el__ob" key={dish.id}>
                  <span className="element__item__el__ob__value">{dish.id}</span>
                  <span className="element__item__el__ob__value">{dish.name}</span>
                </div>
              ))
              : <div>Блюда не подобраны</div>
            }
          </div>
        </div>
      ))}
    </div>
  )
}
