const swap = (arr, i, pos) => {
  // swap elements with their correct indexes
  let temp = arr[i];
  arr[i] = arr[pos];
  arr[pos] = temp;
}

const getMissingNumberInArray = (a, n) => {
  const arr = [...a]
  let i = 0;
  while (i < n) {
    // as array is of 1 based indexing so the
    // correct position or index number of each
    // element is element-1 i.e. 1 will be at 0th
    // index similarly 2 correct index will 1 so
    // on...
    const correctPos = arr[i] - 1;
    if (arr[i] < n && arr[i] !== arr[correctPos]) {
      // if array element should be lesser than
      // size and array element should not be at
      // its correct position then only swap with
      // its correct position or index value
      swap(arr, i, correctPos);
    } else {
      // if element is at its correct position
      // just increment i and check for remaining
      // array elements
      i++;
    }
  }
  // check for missing element by comparing elements with their index values (-1 to keep '0' as result)
  for (let index = -1; index < arr.length; index++) {
    if (arr[index] !== index + 1) {
      return index + 1;
    }
  }
  return n;
}


const getPartsDivision = (sum, length) => {
  const left = Math.ceil(sum / length);
  const right = Math.floor(sum / length);
  const first = (sum - right * length) / right;

  return Array.from({ length }, (_, i) => i < first ? left : right);
}

export {
  getMissingNumberInArray,
  getPartsDivision,
}
