import IngItem from "./IngItem";
import Search from "./Search";
import '../style/inglist.scss'
import { useEffect, useState } from "react";
import {getIng} from '../api'
import Pagination from "./Pagination";
import Preloader from './Preloader'
import { useDispatch, useSelector } from 'react-redux';
import {actionType} from '../constants'

export default function IngList({setChosenName}){

    const [chooseName,setChooseName] = useState([]);
    var [currentPage, setCurrentPage] = useState(1)
    var [total, setTotal] = useState(0)
    const[text,setText] = useState('')
    const[name, setN] = useState();

    const setDispatcher     = useDispatch()
    const ingredients       = useSelector((state) => state.reducer.initial_ing);
    const index             = useSelector((state) => state.reducer.index);

    useEffect(()=>{
        setChosenName(name)
        if(!name) return;
        
        const new_ingr  = [...ingredients]
        new_ingr[index].ingredient_name = name.textIng
        new_ingr[index].ingredient_id   = name.id

        setDispatcher({
            type    : actionType.SET_ING,
            payload : [...new_ingr]
        })
        setDispatcher({
                    type    : actionType.SET_OPEN,
                    payload : true
                })
    },[name])
    useEffect(()=>{
        getIng(currentPage,text).then((res) => {
            setChooseName(res.data.data)
            setTotal(res.data.meta.total)
        })
    },[currentPage,text]) 

    const onPageChanged = (currentPage)=>{
        if(currentPage.currentPage == undefined){
            return;
        }
        setCurrentPage(currentPage.currentPage)
    }   
    return(
       <div className="ing-list z-depth-3">
            <div className='ing-list-content'>
                <div className="search-ing">
                <Search cb = {(e)=>{setText(e)}}/>
                </div>
                <div className="row justify-content-between align-items-center heading-ing">
                    <div className="col-md-10 col-7 col-sm-8">
                        <span>Название</span>
                    </div>
                    <div className="col-md-2 col-5 col-sm-4">
                        <span>Действия</span>
                    </div>
                </div>
                {
                    chooseName ? chooseName.map((el,index)=>{
                        return <div className="ing-one-item" key={index}>
                            <IngItem 
                            name={chooseName[index].name}
                            id_={chooseName[index].id}
                            setName={setN}
                            />
                        </div> 
                    }):null
                }
                <div className="row">
                <div>
                    {
                        total ? <Pagination
                            totalRecords={total}
                            pageLimit={chooseName.length}
                            pageNeighbours={1}
                            onPageChanged={onPageChanged}
                            currentPage={currentPage}
                        />
                        : <Preloader/>
                    }
                </div>
                </div>
        </div>
       </div>
    )
}