import '../style/recipes.scss'
export default function ListProducts({listProducts}){
    return(
        <div className="products">
            <table>
                <thead>
                    <tr>
                      <th>Code</th>
                      <th>Name</th>
                      <th>Weight</th>
                      <th>Price</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        listProducts? listProducts.map((list,listindex)=>(
                           <tr key={listindex}>
                                <td>{list.magnum_code}</td>
                                <td>{list.name}</td>
                                <td>{list.weight+" "+list.unit}</td>
                                <td>{list.price}</td>
                           </tr>
                        )):null 
                    }
                </tbody>
            </table>
     </div>
    )
}