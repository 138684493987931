export default function InputNumber({cb,defaultValue,name}){
    return(
        <input 
        type="number" 
        defaultValue={defaultValue}
        min='0'
        name={name}
        required
        className='input-number'
        onChange={(e)=>(
            cb(e)
        )}
        />
    )
}