import InputTextType from '../component/InputTextType';
import InputPassword from '../component/InputPassword'
import {useState,useEffect} from 'react'
import '../style/login.scss'
import {login} from '../api'
import { useHistory } from 'react-router';
export default function Login(){
    const [userV, setUserV] = useState({
        email:'',
        password:''
})
const[error,setError] = useState()
const[showError,setShowError] = useState('')
const[clicked,setClicked] = useState(false)
  var history = useHistory();
  const changeEmail = (e)=>{
      e.preventDefault();
    setUserV({...userV,email:e.target.value})
  }


  const changePsw = (e)=>{
    e.preventDefault();
  setUserV({...userV,password:e.target.value})
  }


  function reg(e){
    e.preventDefault()
    setError()
    login(userV.email,userV.password,setError)  
    setClicked(true)  
  }
  useEffect(() => {
   if(clicked==true){
    error!=undefined? setShowError('Please, enter the correct email or password!') : history.push('/main')
   }
  }, [error,clicked])
  console.log(error,clicked)
return(
      <div className='loginBody container'>
       <h2 className='blue-text text-darken-2'>Nutristeppe Admin</h2>
        <form onSubmit={reg}>
          <div className="input-field">
          <InputTextType placeholderText='email' cb = {changeEmail}/>
            <label for='link'>Email</label>      
          </div>
           <div className="input-field">
           <InputPassword placeholderText='password' cb = {changePsw}/>
            <label for='password'>Password</label>
           </div>
            <input className='btn blue lighten-2' type="submit" value="Log in"/>
        </form>
          <p className='red-text small'>{showError}</p>
    </div>
)
}