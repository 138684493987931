import { useEffect ,useState} from 'react'
import {getUnitTypes} from '../api'
export default function ChosenIngItem({cb,defaultValue}){
    const[unit, setUnit] = useState([])
    useEffect(()=>{
        getUnitTypes(setUnit);  
    },[])
    return(
        <select 
        className='browser-default' 
        value={defaultValue} 
        onChange={(e)=>(cb(e))}
        >
               {
                   unit.data ? unit.data.map((el,index)=>(
                       <option 
                        key ={index} 
                        value={unit.data[index].id}
                        >
                        {`${unit.data[index].name} (${unit.data[index].volume}gr.)`}
                        </option>
                   )):null
               }
        </select>
    )
}