import { useEffect ,useState} from 'react'
import {getCookingTypes} from '../api'
export default function TypeIng({cb,defaultValue}){
    const[unit, setUnit] = useState([])
    useEffect(()=>{
        getCookingTypes(setUnit);  
    },[])
    return(
        <select className='browser-default'   value={defaultValue}  onChange={(e)=>(cb(e))} >
               {
                   unit.data ? unit.data.map((el,index)=>(
                       <option 
                       key ={index} 
                       value={unit.data[index].id}
                       >
                           {unit.data[index].name}</option>
                   )):null
               }
        </select>
    )
}