function InputTextType(props){
    const {
        cb,
        placeholder
    } = props
    return(
        <input 
            type="text" 
            name="link"
            id='link'
            placeholder = {placeholder}
            onChange={(e)=>{
                    cb(e)
            }}
        />
    )
}
export default InputTextType;